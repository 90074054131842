import { graphql, Link } from 'gatsby';
import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import DefaultLayout from '../layouts/default-layout';
import RoundedButton from '../components/buttons/roundedButton';
import SectionNavigation from '../components/corecurriculum/sectionNavigation';
import { makeAnchor } from '../components/article/faqList';
import { navigate } from 'gatsby-link';
import * as style from './authorList.module.scss';

class AuthorList extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0,
		};
		this.sections = [];
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.handleScrollListener = this.handleScrollListener.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('scroll', this.handleScrollListener);
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScrollListener);
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	handleScrollListener(event) {
		const scrollID = (this.sections.length > 1) ? this.sections[1].section_heading : this.sections[0].section_heading;
		let advisorEl = document.querySelector('#' + scrollID.trim());
		if(advisorEl) {
			let elDistanceToTop = window.pageYOffset + advisorEl.getBoundingClientRect().top;
			let toTopWrap = document.getElementById("to-top-wrap");
			if (document.scrollingElement.scrollTop > elDistanceToTop) {
				toTopWrap.classList.add('opacity-1');
			} else {
				toTopWrap.classList.remove('opacity-1');
			}
		}
	}

	handleScrollToTop() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		setTimeout(() => {
			window.location.hash = "";
		}, "1000");
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}

	startPage = () => {
		this.setState({ startPage: true });
	};

	render() {
		const {
			title,
			our_team_subhead,
			projects_callout_heading,
			projects_callout_image,
			projects_button_text,
			projects_page_link,
			meta_image,
			meta_description,
			author_types
		} = this.props.data.allItemsJson.edges[0].node;

		const {
			path
		} = this.props;
		let itemsSlug = '';
		let linkBack = '';
		let linkBackName = '';
		if(path === '/fellows') {
			itemsSlug = '/fellows/';
			linkBack = '/fellows'
			linkBackName = 'Fellows';
		} else { 
			itemsSlug = (path) ? path + '/' : '/our-team';
		}

		const authors = this.props.data.authors.edges;
		const authorGroups = [];
		const self = this;
		const sectionIndexes = [];

		this.sections = [];
		
		authors.forEach(function(element) {
			if (author_types.indexOf(element.node.staff_type) >= 0) {
				if (sectionIndexes.indexOf(element.node.staff_type) < 0) {
					sectionIndexes[sectionIndexes.length] = element.node.staff_type;
					authorGroups.push([]);
					
				}
				const sectionIndex = sectionIndexes.indexOf(element.node.staff_type);
				authorGroups[sectionIndex].push(element.node);
			}
		});

		if(Array.isArray(author_types)) {
			author_types.forEach(function (authorType) {
				if(sectionIndexes.indexOf(authorType) >= 0) {
					self.sections.push({ section_heading: authorType });
				}
			});
		}

		return (
			<DefaultLayout {...this.props} excludeParentFunctions>
				<div>
					<HeadTags
						title={title}
						location={this.props.location}
						image={meta_image ? meta_image.sizes.hero_md : null}
						imageTwitter={meta_image ? meta_image.sizes.hero_sm : null}
						description={meta_description}
					/>
					<div id="spacer" className={style.spacer}>&nbsp;</div>

					<div
						id="to-top-wrap"
						className={style.toTopWrap}
						onClick={(e) => this.handleScrollToTop()}
					>
						<a
							className={`icon-circle-orange fa-arrow-up ${style.toTopButton}`}
							rel="noopener"
						>
							<span>TOP</span>
						</a>
						<span className={style.toTopText}>TOP</span>
					</div>

					<div id="our-team-header" className={style.authorListHeader}>
						<div className={style.sectionNavigationWrap}>
							<SectionNavigation
								sections={this.sections}
								currentHash={this.props.location.hash}
							/>
						</div>

						<h1 className={style.authorListTitle}>
							{title}
						</h1>
						<p className={style.authorListSubhead}>
							{our_team_subhead}
						</p>
					</div>
					{authorGroups.length > 0 && authorGroups.map(function(group, i) {
						return (
							<div key={`authorgroup` + i}>
								{i % 2 === 0 && 
									<div className={style.container}>
										<div id={makeAnchor(sectionIndexes[i])} className={style.primaryStaff}>
										{i != 0 && 
											<h2 className={`color-white ${style.sectionHeading}`}>
												{sectionIndexes[i]}
											</h2>
										}
										<div className={style.advisorStaffInset}>
											{group.length > 0 && group.map(function(item) {
												return (
													<div className={style.primaryItem} data-sort={item.author_sort}>
														<div
															onClick={() => {
																navigate(
																	itemsSlug+item.slug,
																	{ state: {
																		staff_type: item.staff_type,
																		link_back: linkBack,
																		link_back_name: linkBackName
																	}}
																)
															}}
															className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
														>
															<br />
														</div>
														<h3
															className={style.staffName}
															onClick={() => {
																navigate(
																	itemsSlug+item.slug,
																	{ state: {
																		staff_type: item.staff_type,
																		link_back: linkBack,
																		link_back_name: linkBackName
																	}}
																)
															}}
														>
															{item.title}
														</h3>
														<p 
															onClick={() => {
																navigate(
																	itemsSlug+item.slug,
																	{ state: {
																		staff_type: item.staff_type,
																		link_back: linkBack,
																		link_back_name: linkBackName
																	}}
																)
															}}
															className={style.staffPosition}
														>
															{item.position}
														</p>
														
														<div className={style.socialWrap}>
															{item.twitter_link &&
																<a
																	href={item.twitter_link}
																	className={`icon-social white-orange-hover`}
																	target="_blank"
																	rel="noopener"
																>
																	<span>Twitter</span>
																	<div className={`icon-twitter x-white`}></div>
																</a>
															}
															{item.linkedin_link && item.linkedin_link.uri &&
																<a
																	href={item.linkedin_link.uri}
																	className={`icon-social white-orange-hover fas fa-linkedin-in`}
																	target="_blank"
																	rel="noopener"
																>
																	<span>LinkedIn</span>
																</a>
															}
															{item.bluesky_link && item.bluesky_link.uri &&
																<a
																	href={item.bluesky_link.uri}
																	className={`icon-social white-orange-hover fas fa-bluesky`}
																	target="_blank"
																	rel="noopener"
																>
																	<span>Bluesky</span>
																</a>
															}
															{item.email &&
																<a
																	href={`mailto:${item.email}`}
																	className={`icon-fa-all white-orange-hover fas fa-envelope`}
																	target="_blank"
																	rel="noopener"
																>
																	<span>Email</span>
																</a>
															}
														</div>
													</div>
												);
											})}
											</div>
										</div>
									</div>
								}
								{i % 2 === 1 && 
									<div className={style.bgGrey} key={`authorgroup` + i}>
										<div className={style.container}>
											<div id={makeAnchor(sectionIndexes[i])} className={style.advisorStaff}>
												<h2 className={`color-black ${style.sectionHeading}`}>
													{sectionIndexes[i]}
												</h2>
												<div className={style.advisorStaffInset}>
												{group.length > 0 && group.map(function(item) {
													return (
														<div className={style.advisorItem} data-sort={item.author_sort}>
															<div
																onClick={() => {
																	navigate(
																		itemsSlug+item.slug,
																		{ state: {
																			staff_type: item.staff_type,
																			link_back: linkBack,
																			link_back_name: linkBackName
																		}}
																	)
																}}
																className={style.bioImage} style={{ backgroundImage: 'url('+item.bio_image+')'}}
															>
																<br />
															</div>
															<h3
																className={style.staffName}
																onClick={() => {
																	navigate(
																		itemsSlug+item.slug,
																		{ state: {
																			staff_type: item.staff_type,
																			link_back: linkBack,
																			link_back_name: linkBackName
																		}}
																	)
																}}
															>
																{item.title}
															</h3>
															<p 
																onClick={() => {
																	navigate(
																		itemsSlug+item.slug,
																		{ state: {
																			staff_type: item.staff_type,
																			link_back: linkBack,
																			link_back_name: linkBackName
																		}}
																	)
																}}
																className={style.staffPosition}
															>
																{item.position}
															</p>
															
															<div className={style.socialWrap}>
																{item.email &&
																	<a
																		href={`mailto:${item.email}`}
																		className={`icon-fa-all black-orange-hover fas fa-envelope`}
																		target="_blank"
																		rel="noopener"
																	>
																		<span>Email</span>
																	</a>
																}
																{item.twitter_link &&
																	<a
																		href={item.twitter_link}
																		className={`icon-social black-orange-hover`}
																		target="_blank"
																		rel="noopener"
																	>
																		<span>Twitter</span>
																		<div className={`icon-twitter x-black`}></div>
																	</a>
																}
																{item.linkedin_link && item.linkedin_link.uri &&
																	<a
																		href={item.linkedin_link.uri}
																		className={`icon-social black-orange-hover fas fa-linkedin-in`}
																		target="_blank"
																		rel="noopener"
																	>
																		<span>LinkedIn</span>
																	</a>
																}
																{item.bluesky_link && item.bluesky_link.uri &&
																	<a
																		href={item.bluesky_link.uri}
																		className={`icon-social black-orange-hover fas fa-bluesky`}
																		target="_blank"
																		rel="noopener"
																	>
																		<span>Bluesky</span>
																	</a>
																}
															</div>
														</div>
													);
												})}
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						);
					})}
					
					<div
						className={style.projectsCallout}
					>

						<div
							className={style.projectsCalloutImage}
							style={{ backgroundImage: 'url('+projects_callout_image+')'}}
						></div>

						<p className={style.projectsCalloutHeading}>
							{projects_callout_heading}
						</p>

						<span className={style.textLine} />

						<div
							className={style.projectsButtonWrap}
						>
							<RoundedButton
								centered={true}
								color={'white'}
								text={projects_button_text}
								onClick={() => {
										navigate(projects_page_link)
								}} type="button"
								url={projects_page_link}
							/>
						</div>

					</div>
					{/* */}

				</div>
			</DefaultLayout>
		);
	}
}

export default AuthorList;

// eslint-disable-next-line
export const corecurriculumPageQuery = graphql`
query AuthorListPage(
	$path: String!
	$menuId: String!
	$isEs: Boolean!
	$idRegex: String!
	$blog: String!
	$translations: String!) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
			meta_image {
				sizes {
					hero_md
					hero_sm
				}
			}
			meta_description
		  path
		  meta_description
		  short_description
		  title
		  our_team_subhead
			projects_callout_heading
			projects_callout_image
			projects_button_text
			projects_page_link
			author_types
		}
	  }
	}
	authors: allItemsJson(
		filter: {content_type: {eq: "author"}, staff_type: {ne: ""}, testing: {eq: false}},
		sort: {fields: author_sort, order: ASC}
	) {
		edges {
			node {
				jsonId
				title
				email
				position
				staff_type
				bio_image
				twitter_link
				linkedin_link {
					uri
					title
				}
				bluesky_link {
					uri
					title
				}
				slug
				author_sort
				testing
			}
		}
	}
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
	...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
	...headerFieldsEs @include(if:$isEs)
	...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;